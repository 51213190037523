import React, {useState} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Categories from "../components/categories"
import Header from "../components/header"
import AllWorkFooter from "../components/allWorkFooter"
import Video from "../components/video"
import ReactMarkdown from "react-markdown"
import Fade from "react-reveal/Fade";

const OurWork = ({ data }) => {
  const [activeCat, setActiveCat] = useState('all');
  const handleClick = category => setActiveCat(category);

  const our_work_videos = data.strapiOurwork.our_work_videos.map(our_work_video => our_work_video.video);

  const videos = our_work_videos.filter(video => {
    const categories = video.categories.map(category => category.category_name)     
    return categories.includes(activeCat)
  })

  const activeVideos = activeCat !== 'all' ? videos : our_work_videos

  
  return (
    <Layout>
      <Seo title="Our Work" />

      <Header />

      <div className="pt-32 md:pt-40 lg:pt-60">
        <Fade><h1 className="intro-title">Our Work</h1></Fade>
        <Fade><ReactMarkdown className="intro-paragraph">{data.strapiOurwork.our_work_intro}</ReactMarkdown></Fade>
        <Fade><Categories handleClick={handleClick} activeCat={activeCat}/></Fade>
      </div>
      
      <div className="grid md:grid-cols-2">
        {activeVideos.map(video => <Video key={video.id} video={video} />)}
      </div>

      {activeVideos.length === 0 && (
        <div className="text-white text-l md:text-xl text-center w-10/12 mx-auto">
          Sorry, there are no videos matching that category at this time.
        </div>
      )}

      <AllWorkFooter />
    </Layout>
  )
}

export default OurWork

export const query = graphql` 
  query {
    strapiOurwork {
      our_work_intro
      our_work_videos {
        video {
          id
          animator
          categories {
            category_name
          }
          client
          director
          editor
          photographer
          producer
          retoucher
          shooter
          title
          vimeoID
          photography {
            alternativeText
            url
            formats {
              large {
                publicURL
                relativePath
              }
            }
          }
          poster_image {
            publicURL
          }
        }
      }
    }
  }
`
