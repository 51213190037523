import * as React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function Categories( {handleClick, activeCat} ) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiCategories(sort: {fields: id}) {
            edges {
              node {
                category_name
                id
              }
            }
          }
        }
      `}
      render={data => (
        <div className="w-full overflow-hidden">
          <div className="flex
                        flex-row 
                        text-gray text-xl lg:text-2xl text-center
                        pb-8 lg:pb-20
                        overflow-auto
                        ml-4 md:mx-auto
                        md:justify-center
                        ">
            {data.allStrapiCategories.edges.map((category, i) => {
              return ( 
                <button 
                  key={i} 
                  className={`whitespace-nowrap mx-4 my-1 lg:mx-5 capitalize focus:outline-none targetedHover:hover:text-pink duration-200
                  ${activeCat === category.node.category_name ? 'text-pink' : ''} 
                  ${i === data.allStrapiCategories.edges.length - 1 ? 'pr-5 md:pr-0' : ''}`}
                  onClick={() => handleClick(category.node.category_name)}
                >
                  {category.node.category_name}
                </button>
              )
            })}
            <button 
              className={`mx-4 my-1 lg:mx-5 capitalize focus:outline-none ${activeCat === 'all' ? 'text-pink' : ''}`}
              onClick={() => handleClick('all')}
            >
              All
            </button>
          </div>
        </div>
      )}
    />
  )
}